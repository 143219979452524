.CalendarDay {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #262a3e;
  height: 24px !important;
  margin: 4px !important;
  cursor: pointer;
}

.CalendarDay:hover {
  border-radius: 9999px;
  border: none;
}

.CalendarDay__default {
  border: none;
}

.DayPicker_weekHeader_li {
  font-family: "Inter", sans-serif;
  color: #60657a;
  font-size: 14px;
}

.CalendarMonth_caption {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #262a3e;
  font-weight: 500;
  text-transform: lowercase;
}

.DayPickerNavigation_button {
  border: none;
}

.CalendarMonthGrid div:nth-of-type(3) {
  border-left: 1px solid #e9eaf0;
}

.CalendarMonth_caption {
  padding-top: 0px;
}

.DayPicker__withBorder {
  box-shadow: none;
  background: none;
}

.DayPicker {
  background: none;
}

.CalendarDay__selected,
.CalendarDay__selected:hover {
  background: #117664;
}

.CalendarDay__selected_start_no_selected_end {
  border-radius: 9999px !important;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  background: #eff6f6;
  border-radius: 0px !important;
  color: #117664;
}

.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span:hover {
  background: #b5d3d3;
  border-radius: 0px !important;
  color: #117664;
}

.CalendarDay__hovered_span:hover {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.CalendarDay__lastDayOfWeek.CalendarDay__selected_span,
.CalendarDay__lastDayOfWeek.CalendarDay__hovered_span {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.CalendarDay__firstDayOfWeek.CalendarDay__selected_span,
.CalendarDay__firstDayOfWeek.CalendarDay__hovered_span {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.CalendarDay__selected_end {
  color: white !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.CalendarDay__selected_start {
  color: white !important;
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.CalendarDay__selected_start.CalendarDay__lastDayOfWeek {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.CalendarDay__firstDayOfWeek.CalendarDay__selected_end {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.CalendarMonth_verticalSpacing {
  margin-top: 16px;
}

.DayPicker_transitionContainer {
  height: 245px !important;
}

.DayPicker_weekHeader {
  margin-top: -22px;
}

.DayPickerNavigation_button {
  width: 24px;
  filter: invert(38%) sepia(6%) saturate(1427%) hue-rotate(191deg) brightness(97%) contrast(81%);
  border: none !important;
  cursor: pointer;
  position: absolute;
  margin-top: 4px;
}

.DayPickerNavigation:first-child {
  margin-left: 32px;
}

.DayPickerNavigation div:nth-child(2) {
  margin-left: 540px;
}

.DayPickerNavigation_button__disabled {
  filter: brightness(0%) invert(85%) sepia(11%) saturate(306%) hue-rotate(169deg) brightness(92%) contrast(89%);
}

.CalendarDay__outside,
.CalendarDay__blocked_out_of_range {
  color: #b9c6d3;
}

.CalendarDay__selected_start.CalendarDay__lastDayOfWeek,
.CalendarDay__selected_start.CalendarDay__selected_end {
  border-radius: 9999px !important;
}

.CalendarDay__outside.CalendarDay__selected,
.CalendarDay__outside.CalendarDay__selected_span {
  background-color: #f9f9f9 !important;
  color: #b9c6d3 !important;
}

.CalendarMonth_caption {
  text-transform: capitalize;
}
