@media screen and (min-width: 960px) {
  html {
    margin-left: 0;
    margin-right: calc(100% - 100vw);
  }
}

/* MUI add padding-right if scrollbar + drawer, but we have a margin-right that negates the scrollbar */
/* So this prevents views from jumping when opening a drawer */
body {
  padding-right: 0px !important;
}

.invisible-button {
  text-align: inherit;
  color: inherit;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer !important;
  border: 1px solid transparent;
}

.react-grid-layout {
  position: relative;
}
