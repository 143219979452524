@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.striped-orange:disabled {
  background: repeating-linear-gradient(-30deg, #fcf7ee, #fcf7ee 4px, #f0e6d5 4px, #f0e6d5 8px);
}

.striped-turquoise:disabled {
  background: repeating-linear-gradient(-30deg, #dbecec, #dbecec 4px, #e4f4f5 4px, #e4f4f5 8px);
}

.striped-indigo:disabled {
  background: repeating-linear-gradient(-30deg, #f2f3ff, #f2f3ff 4px, #e5e6f7 4px, #e5e6f7 8px);
}

.striped-red:disabled {
  background: repeating-linear-gradient(-30deg, #ffeff3, #ffeff3 4px, #f4dce2 4px, #f4dce2 8px);
}

.striped-brown:disabled {
  background: repeating-linear-gradient(-30deg, #fef0ee, #fef0ee 4px, #f2dfdc 4px, #f2dfdc 8px);
}

.striped-green:disabled {
  background: repeating-linear-gradient(-30deg, #e7fcec, #e7fcec 4px, #daf6e1 4px, #daf6e1 8px);
}

.striped-blue:disabled {
  background: repeating-linear-gradient(-30deg, #eff8ff, #eff8ff 4px, #deebf5 4px, #deebf5 8px);
}

.striped-grey:disabled {
  background: repeating-linear-gradient(-30deg, #f2f3f4, #f2f3f4 4px, #e6e7e8 4px, #e6e7e8 8px);
}

.striped-pink:disabled {
  background: repeating-linear-gradient(-30deg, #fff3fd, #fff3fd 4px, #f7e7f4 4px, #f7e7f4 8px);
}

.striped-cream:disabled {
  background: repeating-linear-gradient(-30deg, #fbfaf4, #fbfaf4 4px, #eceade 4px, #eceade 8px);
}

.striped:disabled {
  background: repeating-linear-gradient(-30deg, #fff, #fff 4px, #f2f4f8 4px, #f2f4f8 8px);
}

/* menu dropshadows */

.menu_dropshadow {
  filter: drop-shadow(0px 8px 24px rgba(59, 59, 95, 0.12));
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
:root {
  --background: white;
  --a1: rgb(213, 216, 213);
  --foreground: #000;
  --shadow: 0px 6px 20px #000;
  --unit: 8px;
}
